/**
 * Project-wide constants for the main routes of the app.
 *
 * So we can replace them easier in the future.
 * For the sub-routes inside them, don't add them here.
 */
export enum PageRoute {
  Dashboard = "/",
  Unauthorized = "/401",
  About = "/about",
}
