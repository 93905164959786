import(/* webpackMode: "eager", webpackExports: ["ConfigProvider"] */ "__barrel_optimize__?names=ConfigProvider!=!/app/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager" */ "/app/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["AuthenticationContextProvider"] */ "/app/components/client/authentication/authentication-context.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/client/communication-log/communication-log-card.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/client/communication-log/communication-log-context.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/client/communication-log/communication-log-select-a-log-placeholder.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/client/communication-log/communication-log.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/client/context/application-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/client/graphql/providers/apollo-client-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/client/network/network-context.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/client/notification/notification-context.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/client/settings/settings-modal.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/client/system-preference/system-preference-context.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/client/twilio/hooks/use-twilio-device.ts");
;
import(/* webpackMode: "eager" */ "/app/components/client/twilio/twilio-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/antd/dist/reset.css");
